.sets {
    width: 100%;
}
.sets h5.quality {
    text-align: center;
}
.sets h5 {
    text-align: left;
    text-size-adjust: 100%;
    overflow-wrap: break-word;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin: 24px 0px;
}
.sets .line {
    margin: 50px auto;
    width: 50%;
    height: 2px;
    background-color: black;
    border-radius: 5px;
}
@media screen and (max-width: 800px) {
    .sets {
        padding: 0px 16px;
    }
    .sets h5 {
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin: 16px 0px;
    }
    .sets h5.quality {
        text-align: left;
    }
    .sets .line {
        margin: 30px auto;
    }
}