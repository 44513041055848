.episode {
    width: 100%;
}
.episode h4 {
    text-align: left;
    text-size-adjust: 100%;
    overflow-wrap: break-word;
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.episode h5 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-bottom: 8px;
    padding: 0;
    text-align: left;
    text-size-adjust: 100%;
    overflow-wrap: break-word;
}
.episode .episodeNumber {
    font-weight: bold;
}
.episode .videoMetaData {
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin: 16px 0px;
}
.episode video{
    margin: 8px 0px;
    width: 100%;
    max-height: 520px;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
}
.episode .iframe {
    margin: 8px 0px;
    width: 100%;
    height: 506px;
    max-height: 520px;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
}
.episode details summary::-webkit-details-marker {
    display:none;
}
.episode details > summary {
    display: flex;
    align-items: center;
    list-style: none;
}
.episode details summary .ExpandButton {
    width: 35px;
    height: 35px;
    position: relative;
    bottom: 5px;
    margin-left: 8px;
}
@media screen and (max-width: 800px) {
    .episode .videoMetaData {
        display: block;
        margin: 8px 0px;
    }
    .episode .videoMetaData h5 {
        text-align: center;
    }
    .episode video {
        max-height: 220px;
    }
    .episode .iframe {
        max-height: 220px;
    }
}