* {
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
}
body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Global font sizes */
h1 {
  font-size: 52px;
}
h2 {
  font-size: 38px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
p {
  font-size: 18px;
}
a {
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
}
@media screen and (max-width: 575px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
}
/* SwiperJS global styling */
.swiper-pagination-bullet-active {
  background-color: black !important;
}
.swiper-button-prev {
  top: 190px !important;
}
.swiper-button-next {
  top: 190px !important;
}
.swiper-button-prev::after {
  color: black !important;
}
.swiper-button-next::after {
  color: black !important;
}
@media screen and (max-width: 800px) {
  div.swiper-button-prev {
    left: 0px;
  }
  div.swiper-button-next{
    right: 0px;
  }
  .swiper-button-prev {
    top: 120px !important;
  }
  .swiper-button-next {
    top: 120px !important;
  }
}