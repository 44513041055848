.header {
    width: 100%;
}
.header a {
    text-decoration: none;
    color: inherit;
}
.header h1,h2 {
    font-weight: 400;
    text-align: center;
    text-size-adjust: 100%;
    overflow-wrap: break-word;
}
.header h1 {
    line-height: 52px;
    letter-spacing: 18px;
}
.header h2 {
    line-height: 40px;
}
.header .line {
    margin: 0 auto;
    width: 100%;
    height: 2px;
    background-color: black;
    border-radius: 5px;
}
@media screen and (max-width: 575px) {
    .header {
        padding: 0px 16px;
    }
    .header h1 {
        letter-spacing: initial;
    }
}