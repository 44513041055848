.app {
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app .container {
  min-width: 400px;
  max-width: 900px;
  flex-grow: 1;
}