.welcome {
    height: 100%;
    width: 100%;
}
.welcome h4 {
    text-align: center;
    text-size-adjust: 100%;
    overflow-wrap: break-word;
}
.welcome .quote {
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.welcome .menu {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.welcome .menu .item{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.welcome .menu .item a {
    width: 100%;
    height: 100%;
    color: inherit;
}
/* Animation */
.curationWrapper {
    margin: 0 auto;;
    width: 330px
}
.typewriter  {
    margin: 0 auto;
    color: red;
    overflow: hidden;
    border-right: 4px solid red; /* The typwriter cursor */
    white-space: nowrap;
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 18px; /* Adjust as needed */
    animation: 
        typing 2.5s steps(8, end),
        blink-caret 1s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: red; }
}

@media screen and (max-width: 800px) {
    .welcome {
        height: 100%;
        padding: 0px 16px;
    }
    .welcome .quote {
        height: 250px;
    }
    .welcome .quote h3 {
        padding: 0px 8px;
        line-height: 35px;
    }
    .welcome h2 span {
        line-height: initial;
    }
    .welcome .menu {
        height: 100%;
    }
    .welcome .menu .item h3 {
        line-height: 35px;
    }
    .curationWrapper {
        margin: 0 auto;;
        width: 270px
    }

}