.special {
    width: 100%;
}
.special li {
    margin: 16px 0px;
}
.special h4 {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
}
.special .date {
    font-weight: bold;
}
.special h5 {
    text-align: left;
}
.special .submissionDiv {
    margin-top: 16px;
}
.special .submissionDiv h5 {
    margin-bottom: 16px;
}
.special input {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
}
.special form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.special button {
    width: 100px;
    height: 50px;
}
.special .error {
    text-align: center;
}
@media screen and (max-width: 800px) {
    .special {
        padding: 0px 16px;
    }
    .special h5 {
        margin-bottom: 8px;
    }
}