.eventsPage {
    height: 800px;
}
.eventsPage h4 {
    text-align: center;
}
.eventsPage .eventButton {
    height: 125px;
    width: 100%;
    border-color: black;
    background-color: white;
    margin-bottom: 32px;
}
.eventsPage .eventButton:hover {
    cursor: pointer;
}
@media screen and (max-width: 800px) {
    .eventsPage {
        height: 100%;
        padding: 0px 16px;
    }
    .eventsPage .instagramWrapper .instagram {
        height: 100px;
        width: 100px;
    }    
}